import React from 'react'
import { Link } from 'gatsby'
import './section.css'

export default function Section(props) {
    return (
      <Link to={props.to} className="section-link">
        <div className={props.direction ? 'section section-left' : 'section'}>
            <div className="section-content">
                {/* <p class="section-meta">{props.company}</p> */}
                <h2 className="section-title">{props.title}</h2>
                {props.children}
            </div>

            <div className="section-image">
              <img src={props.src} alt={props.alt}/>
            </div>
        </div>
      </Link>
    )
}
