import * as React from 'react'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout'
import Section from '../components/section/section'
// import CVImage from '../images/CitiVelocity/Citi Velocity.png'
// import PWImage from '../images/pw-fund.png'
// import CDImage from '../images/client-dashboard-new.png'
// import CascadeImage from '../images/cascade.png'
// import ICGDSButtonsDark from '../images/ICGDS/ICGDS Button.png'
import CVHome from '../images/CitiVelocity/Citi Velocity Home.png'
import PWHome from '../images/PortfolioWorkbench/Portfolio Workbench Home.png'
import ClientDashboardHome from '../images/ClientDashboard/Client Dashboard Home.png'
import CascadeHome from '../images/cascade/Cascade Home.png'
import PersonalImage from '../images/leland.jpeg'

const IndexPage = () => {
  return (
    <main className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <title>Leland Riordan - Portfolio - Home</title>
      </Helmet>
      <Layout>
        <div className="main-content">
          <div className="callout">
            <div className="callout-image-mask">
              <img src={PersonalImage} className="callout-image" alt='Portfolio Workbench Individual Fund Page'/>
            </div>
            <div className="callout-content">
              <h2 className="callout-title">Hi, I'm Leland.</h2>
              <p className="lead">
                I am a product design leader with 10+ years of experience designing delightful user experiences and 7+ years of experience managing design teams and systems.
                Currently, I am the Head of UX for Citi Digital Solutions and Citi Velocity, the firm's flagship analytics and content platform.
              </p>
            </div>
          </div>

          <div className="work-section">
            <h2 className="work-section-title">My Work</h2>

            <Section
              title="Citi Velocity"
              company="Citi"
              to="/citi-velocity"
              src={CVHome}>
                <p>Citi Velocity is the #1 ranked analytics platform on Wall St. and the face of Citi's markets, trading and investment banking businesses.
                  In my role as Head of UX, I led a ground up redesign of the platform and overhaul of its information architecture.</p>
            </Section>

            <Section
              title="Cascade"
              to="/cascade"
              direction="left"
              src={CascadeHome}>
                <p>Cascade is a new marketplace that transforms the ESG industry by allowing companies to quickly purchase green energy.
                  In the fall of 2021, I had three weeks to rapidly design Cascade for its successful seed funding pitch to investors.</p>
            </Section>

            {/* <Section
              title="ICG Design System"
              to="/icgds"
              direction="left"
              src={ICGDSButtonsDark}>
                <p>Learn how I partnered with other design leaders to take a small React library, transform it into a full fledged design system and then roll it out as the standard across products at Citigroup.</p>
            </Section> */}

            {/* <Section
              title="CeeVee &amp; Symphony"
              to="/citi-velocity"
              src={CVImage}>
                <p>CeeVee is a chatbot built on top of the Symphony platform (Slack for Wall St.) that uses AI and machine learning to automate trades for Citi Velocity's clients.</p>
            </Section> */}

            <Section
              title="Portfolio Workbench"
              to="/portfolio-workbench"
              src={PWHome}>
                <p>
                Portfolio Workbench is Cambridge Associates' flagship portfolio management, analytics and trading platform.
                From 2016 to 2019, I led UX design and front end development from ideation to launch of the platform.
                </p>
            </Section>

            {/* <Section
              title="Cambridge Associates Website"
              to="/client-dashboard"
              direction="left"
              src={ClientDashboardBrowser}>
                <p>
                  Aggregating information from across products at Cambridge Associates into a centralized hub, the Client Dashboard is the centerpiece of the Cambridge Associates client experience.
                  I won the firm's' Innovation award for leading the design and launch of this product in 2015.
                </p>
            </Section>     */}

            <Section
              title="Client Dashboard"
              to="/client-dashboard"
              direction="left"
              src={ClientDashboardHome}>
                <p>
                  Aggregating information from across products at Cambridge Associates into a centralized hub, the Client Dashboard is the centerpiece of the Cambridge Associates client experience.
                  I won the firm's' Innovation award for leading the design and launch of this product in 2015.
                </p>
                {/* <p>
                  The Client Dashboard aggregates content and analytics from across products at the firm into a centralized hub and serves as the homepage for Cambridge Associates clients.
                  I won the firmwide Innovation award for leading the launch and rollout of this product in 2015.
                </p> */}
            </Section>      
          </div>
        </div>
      </Layout>
    </main>
  )
}

export default IndexPage
